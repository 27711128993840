// @flow
import * as React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Image from "next/image";
import { Carousel } from "antd";
import { useRef, useState } from "react";
import { convertToI18, formatDuration, formatNumber } from "@/utils/count";
import { MusicData, TagData } from "@home3/homeType";
import { handleToUrl } from "@/utils/sys";

type Props = {
    MusicData: MusicData[];
    TagData: TagData[];
};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const { MusicData, TagData } = props;
    const router = useRouter();
    let [isFast, setFast] = useState(true);
    const carouselRef = useRef(null);
    const handClick = (suburl: string) => {
        // handleToMoblieList("Contents", suburl);
        handleToUrl(suburl);
    };
    const toChangeTab = (current: number) => {
        setFast(current === 0);
    };
    const ChangeCarouse = (index: number) => {
        //@ts-ignore
        carouselRef?.current?.goTo(index);
    };

    return (
        <div className={`${styles.user}`}>
            <div className={`${styles.container}`}>
                <div className={styles.tap}>
                    <ul>
                        <li>
                            <a
                                style={{
                                    color: isFast
                                        ? "#2E384E"
                                        : "rgba(46,56,78,0.4600)",
                                }}
                                onClick={() => ChangeCarouse(0)}
                            >
                                {t("Music")}
                                <div
                                    className={styles.bar}
                                    style={{ display: !isFast ? "none" : "" }}
                                ></div>
                            </a>
                        </li>
                        <li>
                            <a
                                style={{
                                    marginLeft: "calc(100vw * (20 / 375))",
                                    color: isFast
                                        ? "rgba(46,56,78,0.4600)"
                                        : "#2E384E",
                                }}
                                onClick={() => ChangeCarouse(1)}
                            >
                                {t("Hashtags")}
                                <div
                                    className={styles.bar}
                                    style={{ display: isFast ? "none" : "" }}
                                ></div>
                            </a>
                        </li>
                    </ul>
                    <div
                        className={styles.more}
                        onClick={() =>
                            handClick(
                                isFast
                                    ? "/mobile/content/songs"
                                    : "/mobile/content/hashtags"
                            )
                        }
                    >
                        {t("_more")}
                    </div>
                </div>
                <Carousel
                    afterChange={toChangeTab}
                    ref={carouselRef}
                    speed={100}
                    dots={false}
                >
                    <div className="px-[20px]">
                        <div className={styles.itemTitle2}>{t("update1")}</div>
                        {(MusicData || []).map((item: any, index: any) => (
                            <div
                                key={index}
                                className={styles.item}
                                onClick={() =>
                                    handClick("/mobile/content/songs")
                                }
                            >
                                <div className={styles.left}>
                                    <div className={styles.pic}>
                                        {
                                            <div>
                                                <Image
                                                    lazyBoundary="10px"
                                                    src={`https://ttcdn.tikmeta.com/tikmeta/${item.coverLargePrivatization}`}
                                                    width={480}
                                                    height={480}
                                                    alt=""
                                                    style={{
                                                        borderRadius: "8px",
                                                    }}
                                                ></Image>
                                            </div>
                                        }
                                    </div>
                                    {
                                        <div className={styles.duration}>
                                            {formatDuration(item.duration)}
                                        </div>
                                    }
                                </div>
                                <div className={styles.center + " flex"}>
                                    <div className={styles.up}>
                                        <div className={styles.title}>
                                            {item.title}
                                        </div>
                                        <div className={styles.count}>
                                            {t("Videos")}
                                            {" " +
                                                convertToI18(item.videoCount)}
                                        </div>
                                    </div>

                                    <div className={styles.down}>
                                        <div className={styles.videoCount}>
                                            <span style={{ color: "#12B939" }}>
                                                ↑
                                            </span>
                                            {formatNumber(item?.videoCountIncr)}
                                        </div>
                                        <div className={styles.count}>
                                            {t("videosGrowth")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="px-[20px]">
                        <div className={styles.itemTitle2}>{t("update1")}</div>
                        {(TagData || []).map((item: any, index: any) => (
                            <div
                                key={index}
                                className={styles.item}
                                onClick={() =>
                                    handClick("/mobile/content/hashtags")
                                }
                            >
                                <div className={styles.left}>
                                    <div className={styles.pic}>
                                        {<div className={styles.tag}>#</div>}
                                    </div>
                                </div>
                                <div className={styles.center + " flex"}>
                                    <div className={styles.up}>
                                        <div className={styles.title}>
                                            {"#"}
                                            {item.title}
                                        </div>
                                        <div className={styles.count}>
                                            {t("Views")}
                                            {" " +
                                                convertToI18(item.videoCount)}
                                        </div>
                                    </div>

                                    <div className={styles.down}>
                                        <div className={styles.videoCount}>
                                            <span style={{ color: "#12B939" }}>
                                                ↑
                                            </span>
                                            {formatNumber(item?.viewIncreCount)}
                                        </div>
                                        <div className={styles.count}>
                                            {t("viewsGrowth")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default Index;
